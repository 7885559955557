import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Row, Col, ProductBookCard, Button, Video } from '../../components/global'
import config from '../../siteConfig'

const H111A = () => (
  <Layout>
    <SEO title="The Starter Kit, H-111A" />
    <div className="container container-last">
      <h1>The Starter Kit, H-111A</h1>
      <p className="boost">
        Learn the principles of testing and measuring live vehicle electrical circuits with your
        DMM.
      </p>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard
            title="The Starter Kit, H-111A"
            step="Hands-on Kit"
            animate={false}
            price={279.0}
          />
        </Col>
        <Col width="68%" align="left">
          <p>
            This circuit board shows you how to conclusively use voltage drop testing to identify
            circuit problems &mdash; especially those illusive and intermittent ones.
          </p>
          <p>
            You will practice electrical troubleshooting by following 32 programmed troubleshooting
            problems that will teach you to find all types of electrical problems at any point in
            the circuit.
          </p>
          <p className="boost">Electrical circuit troubleshooting is easy when you know how!</p>
          <p>
            <Button
              href="/products/circuit-board-trainer-inventory-clearance"
              text="See purchasing options"
              type="secondary"
            />
            <span className="meta button-meta">
              Power Supplies for UK and Europe are substituted for $25.00 additional cost each.
              Please specify in your order which one (Euro or UK) you need. Shipping outside a USA
              zip code is an additional shipping charge.
            </span>
          </p>
        </Col>
      </Row>
      <hr />
      <p className="boost">See The Starter Kit, H-111A in action.</p>
      <Video
        title="See The Starter Kit, H-111A in action"
        src="https://www.youtube.com/embed/wIWic4sGgvA"
      />
      <hr />
      <Row>
        <Col width="49%" align="left">
          <p>This Starter Kit contains:</p>
          <ul>
            <li>1 ea. 13.8V Power Supply H-PS01</li>
            <li>1 ea. Power Circuit Board H-PCB01A</li>
            <li>1 ea. Lamp Circuit Board H-PCB02A</li>
            <li>1 ea. Student Workbook H-WB111A</li>
            <li>1 ea. Instructor Guide H-IG111A</li>
            <li>1 ea. Resistor Bag H-RB01/02</li>
          </ul>
        </Col>
        <Col width="49%" align="left">
          <Image file="h-111a.jpg" />
        </Col>
      </Row>
      <hr />
      <h2>What Next?</h2>
      <p>
        Continue developing your troubleshooting skills and learning more about auto/truck
        electrical/electronic troubleshooting with the following live circuit troubleshooting
        trainers!
      </p>
      <ul>
        <li>
          <Link to="/products/h-113">H-113(S) Troubleshooting DC Motor Circuits</Link>
        </li>
        <li>
          <Link to="/products/h-115">H-115(S), Relay Circuit Troubleshooting</Link>
        </li>
        <li>
          <Link to="/products/h-116">Wire Harness Troubleshooting Trainer H-116</Link>
        </li>
        <li>
          <Link to="/products/h-200">CAN Bus Troubleshooting Trainer H-200(S)</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default H111A
